// App.js
import React from 'react';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Navbar from './components/Navbar';
import Header from './components/Header';
import Gallery from './components/Gallery';
import Contacts from './components/Contacts';
import Footer from './components/Footer';
import LicenseComponent from './components/LicenseComponent';



const App = () => {
  return (
    <Router>
      
      <div>
        <Navbar />
        <Header />
       
        <main>
          <Routes>
            {/* Путь для галереи */}
            <Route path="/" element={<Gallery />} />

            {/* Путь для контактов */}
            <Route path="/contacts" element={<Contacts />} />

            {/* Путь для лицензии */}
            <Route path="/license" element={<LicenseComponent />} />

          
          </Routes>
        </main>
        <Footer />
      </div>
      
    </Router>
  );
};

export default App;
