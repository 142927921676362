import React, { useState, useEffect } from 'react';
import Modal from 'react-modal';
import { Helmet } from 'react-helmet'; // Импортируйте Helmet

// Объект для сопоставления английских названий категорий с русскими
const categoryTranslations = {
  all: "Все",
  nature: "Природа",
  sea: "Море",
  plants: "Растения",
  qarchitecture: "Архитектура",
};

const categories = Object.keys(categoryTranslations); // Получаем массив категорий из ключей объекта

const Gallery = () => {
  const [images, setImages] = useState([]);
  const [displayedImages, setDisplayedImages] = useState([]);
  const [selectedCategory, setSelectedCategory] = useState("all");
  const [selectedImageIndex, setSelectedImageIndex] = useState(null);
  const [scale, setScale] = useState(1);
  const [cursorPosition, setCursorPosition] = useState({ x: 0, y: 0 });
  const [isPurchaseModalOpen, setIsPurchaseModalOpen] = useState(false);

  const imagesPerPage = 10;

  useEffect(() => {
    const fetchImages = async (category) => {
      try {
        const response = category === "all"
          ? await fetch('/images/all/images.json')
          : await fetch(`/images/${category}/images.json`);

        if (!response.ok) throw new Error('Сетевой ответ был не успешным');

        const imagesArray = await response.json();
        setImages(imagesArray);
        setDisplayedImages(imagesArray.slice(0, imagesPerPage));
      } catch (error) {
        console.error("Ошибка загрузки изображений:", error);
      }
    };

    fetchImages(selectedCategory);
  }, [selectedCategory]);


 

  const loadMoreImages = () => {
    const newImages = images.slice(displayedImages.length, displayedImages.length + imagesPerPage);
    setDisplayedImages((prevImages) => [...prevImages, ...newImages]);
  };

  const handleCategoryChange = (category) => {
    setSelectedCategory(category);
    setDisplayedImages([]);
  };

  const openModal = (index) => {
    setSelectedImageIndex(index);
    setScale(1);
  };

  const closeModal = () => {
    setSelectedImageIndex(null);
    setScale(1);
    setIsPurchaseModalOpen(false);
  };

  const nextImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex + 1) % images.length);
  };

  const prevImage = () => {
    setSelectedImageIndex((prevIndex) => (prevIndex - 1 + images.length) % images.length);
  };

  const handleWheel = (event) => {
    event.preventDefault();
    const newScale = scale + event.deltaY * -0.01;
    setScale(Math.min(Math.max(1, newScale), 3));
  };

  const handleMouseMove = (event) => {
    const rect = event.currentTarget.getBoundingClientRect();
    const x = event.clientX - rect.left;
    const y = event.clientY - rect.top;
    setCursorPosition({ x, y });
  };

  const openPurchaseModal = () => {
    setIsPurchaseModalOpen(true);
  };

  const handleBuy = () => {
    console.log('Покупка изображения:', images[selectedImageIndex]);
    closeModal();
  };

  return (
    <div className="App px-5 py-6 max-w-screen-lg mx-auto">
      <Helmet>
        <title>Галерея Фотографий</title>
        <meta name="description" content="Посмотрите нашу коллекцию изображений природы, моря и растений. Покупайте фотографии для личного и коммерческого использования." />
        <meta name="keywords" content="фотографии, галерея, природа, море, растения" />
      </Helmet>

      <div className="flex flex-wrap justify-center space-x-4 mb-6 gap-2">
        {categories.map((category) => (
          <button
            key={category}
            onClick={() => handleCategoryChange(category)}
            className={`px-4 py-2 rounded-lg ${selectedCategory === category ? 'bg-gray-800 text-white' : 'bg-gray-300 text-black'}`}
          >
            {categoryTranslations[category]} {/* Используем русское название категории */}
          </button>
        ))}
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
        {displayedImages.map((img, index) => (
          <div key={index} className="relative cursor-pointer">
            <img 
              src={img.url} 
              alt={img.alt} 
              className="w-full h-64 object-cover rounded-lg shadow-md hover:shadow-lg transition-shadow duration-150" 
              onClick={() => openModal(index)}
              onContextMenu={(e) => e.preventDefault()} 
              draggable="false" 
            />
          </div>
        ))}
      </div>

      {displayedImages.length < images.length && (
        <div className="flex justify-center mt-4">
          <button
            onClick={loadMoreImages}
            className="px-6 py-2 bg-gray-800 text-white rounded-lg hover:bg-gray-700 transition-colors"
          >
            Показать еще
          </button>
        </div>
      )}

      <Modal
        isOpen={selectedImageIndex !== null}
        onRequestClose={closeModal}
        contentLabel="Изображение"
        className="fixed inset-0 flex items-center justify-center z-50 p-4 mt-4 pt-4"
        overlayClassName="fixed inset-0 bg-black bg-opacity-75"
      >
        {selectedImageIndex !== null && (
          <div
            className="relative flex flex-col items-center justify-center max-w-2xl max-h-full overflow-visible"
            onWheel={handleWheel}
            onMouseMove={handleMouseMove}
          >
            <img
              src={images[selectedImageIndex].url}
              alt="Увеличенное"
              className="max-w-full max-h-[80vh] rounded-lg mx-4 transition-transform duration-300"
              style={{
                transform: `scale(${scale})`,
                transformOrigin: `${cursorPosition.x}px ${cursorPosition.y}px`
              }}
            />
            
            <div className="flex items-center justify-between w-full mt-4">
              <button
                onClick={prevImage}
                className="text-white text-4xl z-10 border-2 border-transparent hover:border-yellow-500 rounded-full p-2 transition-all duration-300"
              >
                ❮
              </button>

              <span className="text-white text-xl z-10">
                Масштаб: {Math.round(scale * 100)}%
              </span>

              <button
                onClick={nextImage}
                className="text-white text-4xl z-10 border-2 border-transparent hover:border-yellow-500 rounded-full p-2 transition-all duration-300"
              >
                ❯
              </button>
            </div>

            <button
              onClick={openPurchaseModal}
              className="flex items-center justify-center bottom-2 right-2 px-4 py-2 bg-green-600 text-white rounded-lg hover:bg-green-500"
            >
              Купить
            </button>

            <button
              onClick={closeModal}
              className="absolute text-white text-2xl z-10 border-2 border-transparent hover:border-yellow-500 rounded-full p-2 transition-all duration-300"
              style={{ top: '5px', right: '25px' }}
            >
              ✕
            </button>
          </div>
        )}
      </Modal>

      <Modal
  isOpen={isPurchaseModalOpen}
  onRequestClose={closeModal}
  contentLabel="Покупка"
  className="fixed inset-0 flex items-center justify-center z-50 p-4"
  overlayClassName="fixed inset-0 bg-black bg-opacity-75"
>
  <div className="bg-white rounded-lg p-6 max-w-sm mx-auto">
    <h2 className="text-xl font-bold mb-4">Информация о покупке</h2>
    <p>Вы собираетесь купить изображение:</p>
    {selectedImageIndex !== null && (
      <img
        src={images[selectedImageIndex].url}
        alt="Покупаемое изображение"
        className="w-full h-32 object-cover rounded-lg mb-4"
      />
    )}
    <p className="mb-4">Цена: <strong>1000 рублей</strong></p>
    <p className="mb-4">Чтобы приобрести изображение, свяжитесь с правообладателем. <a href="/contacts" className="text-blue-500">Перейти к контактам</a></p>
    <button
      onClick={handleBuy}
      className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-500"
    >
      Оплатить
    </button>
    <button
      onClick={closeModal}
      className="ml-2 px-4 py-2 bg-gray-300 text-black rounded-lg hover:bg-gray-200"
    >
      Отмена
    </button>
  </div>
</Modal>

    </div>
  );
};

export default Gallery;
