import React from 'react';

const GalleryIcon = () => (
<svg
  xmlns="http://www.w3.org/2000/svg"
  viewBox="0 0 24 24"
  fill="none"
  stroke="currentColor"
  strokeWidth="2"
  strokeLinecap="round"
  strokeLinejoin="round"
  className="w-8 h-8"  // Размеры иконки
>
  <path d="M23 20v-14a2 2 0 0 0-2-2h-3.5l-2-2h-9l-2 2H3a2 2 0 0 0-2 2v14a2 2 0 0 0 2 2h20a2 2 0 0 0 2-2z" />
  <circle cx="12" cy="12" r="4" />
</svg>


  
  



);

export default GalleryIcon;
