// Header.js
import React from 'react';
import { Helmet } from 'react-helmet'; // Импортируйте Helmet для метаданных

const Header = () => {
  return (
    <header id="header" className="text-black py-4 px-5 text-center">
      <Helmet>
        <title>Фотогалерея</title>
        <meta name="description" content="Добро пожаловать в нашу фотогалерею, где вы можете просмотреть и приобрести изображения природы, моря и растений." />
      </Helmet>
      <h1 className="pt-10 md:pt-16 text-4xl md:text-6xl font-bold">Фотогалерея</h1>
    </header>
  );  
};

export default Header;
