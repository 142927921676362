// Contacts.js
import React, { useState } from 'react';
import { FaPhone, FaEnvelope, FaTelegramPlane, FaVk } from 'react-icons/fa';

const Contacts = () => {
  const [name, setName] = useState('');
  const [email, setEmail] = useState('');
  const [phone, setPhone] = useState('');
  const [telegram, setTelegram] = useState('');
  const [message, setMessage] = useState('');

  const handleSubmit = async (e) => {
    e.preventDefault();
    const messageText = `Новое сообщение:\nИмя: ${name}\nEmail: ${email}\nТелефон: ${phone}\nTelegram: ${telegram}\nСообщение: ${message}`;

    try {
      await fetch(`https://api.telegram.org/bot7108101825:AAFN0fykAJFO2i-ke-uaMzIi13Uvt8qQKr4/sendMessage`, {
        method: 'POST',
        headers: { 'Content-Type': 'application/json' },
        body: JSON.stringify({
          chat_id: '850487330',
          text: messageText,
        }),
      });
      // Очистка полей после отправки
      setName('');
      setEmail('');
      setPhone('');
      setTelegram('');
      setMessage('');
      alert('Сообщение отправлено!');
    } catch (error) {
      console.error('Ошибка при отправке сообщения:', error);
      alert('Не удалось отправить сообщение. Попробуйте еще раз.');
    }
  };

   return (<div id="contacts" className="py-10 px-5 max-w-screen-lg mx-auto text-center">
    <h2 className="text-3xl font-bold mb-6">Свяжитесь с нами</h2> {/* Увеличен размер заголовка */}
    <div className="space-y-4">

    
    <form onSubmit={handleSubmit} className="bg-white p-8 rounded-xl shadow-lg max-w-lg mx-auto space-y-6">
  <div>
    <label className="block text-gray-700 font-medium mb-2" htmlFor="name">Ваше имя</label>
    <input
      id="name"
      type="text"
      placeholder="Введите ваше имя"
      value={name}
      onChange={(e) => setName(e.target.value)}
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:bg-gray-50 transition"
    />
  </div>

  <div>
    <label className="block text-gray-700 font-medium mb-2" htmlFor="email">Ваш Email</label>
    <input
      id="email"
      type="email"
      placeholder="Введите ваш email"
      value={email}
      onChange={(e) => setEmail(e.target.value)}
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:bg-gray-50 transition"
    />
  </div>

  <div>
    <label className="block text-gray-700 font-medium mb-2" htmlFor="phone">Ваш номер телефона</label>
    <input
      id="phone"
      type="tel"
      placeholder="Введите ваш номер телефона"
      value={phone}
      onChange={(e) => setPhone(e.target.value)}
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:bg-gray-50 transition"
    />
  </div>

  <div>
    <label className="block text-gray-700 font-medium mb-2" htmlFor="telegram">Ваш Telegram</label>
    <input
      id="telegram"
      type="text"
      placeholder="Введите ваш Telegram"
      value={telegram}
      onChange={(e) => setTelegram(e.target.value)}
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:bg-gray-50 transition"
    />
  </div>

  <div>
    <label className="block text-gray-700 font-medium mb-2" htmlFor="message">Ваше сообщение</label>
    <textarea
      id="message"
      placeholder="Введите ваше сообщение"
      value={message}
      onChange={(e) => setMessage(e.target.value)}
      rows="4"
      className="w-full p-3 border border-gray-300 rounded-lg focus:outline-none focus:ring-2 focus:ring-gray-500 focus:bg-gray-50 transition"
    ></textarea>
  </div>

  <button
    type="submit"
    className="w-full bg-gradient-to-r from-gray-500 to-gray-700 text-white py-3 rounded-lg font-semibold text-lg transition duration-300 ease-in-out transform hover:scale-105 hover:shadow-lg"
  >
    Отправить
  </button>
</form>






      {/* Телефон */}
      <a
        href="tel:+1234567890"
        className="text-3xl flex items-center justify-center space-x-2 hover:text-gray-500 transition" // Увеличен размер текста
      >
        <FaPhone className="text-3xl" /> {/* Увеличен размер иконки */}
        <span>+7 (919) ...-....</span>
      </a>

      {/* Почта */}
      <a
        href="mailto:example@mail.com"
        className="text-3xl flex items-center justify-center space-x-2 hover:text-gray-500 transition" // Увеличен размер текста
      >
        <FaEnvelope className="text-3xl" /> {/* Увеличен размер иконки */}
        <span>karimovandr94@mail.ru</span>
      </a>

      {/* Социальные сети */}
      <div className="flex justify-center space-x-6 mt-4">
        <a
          href="https://vk.com"
          target="_blank"
          rel="noopener noreferrer"
          className="text-5xl hover:text-gray-500 transition" // Увеличен размер иконки
        >
          <FaVk />
        </a>
        <a
          href="https://t.me/Azorubin"
          target="_blank"
          rel="noopener noreferrer"
          className="text-5xl hover:text-gray-500 transition" // Увеличен размер иконки
        >
          <FaTelegramPlane />
        </a>
      </div>
    </div>
  </div>
);
};

export default Contacts;
