import React from 'react';

import { Link as ScrollLink } from 'react-scroll';
import { Link } from 'react-router-dom';
import GalleryIcon from './GalleryIcon';

const Navbar = () => {
  

  return (
    <nav className="bg-gray-800 text-white py-3 px-5 shadow-lg fixed top-0 w-full z-50 mb-2">
      <ul className="flex justify-center items-center space-x-6">
        <li className="hover:text-gray-300 cursor-pointer flex items-center">
          <ScrollLink to="header" spy={true} smooth={true} offset={-100} duration={500}>
            <Link to="/" className="flex items-center">
              <GalleryIcon />
              <span className="ml-2">Главная</span>
            </Link>
          </ScrollLink>
        </li>

        <li className="hover:text-gray-300 cursor-pointer">
          <Link to="/contacts">Контакты</Link>
        </li>

        <li className="hover:text-gray-300 cursor-pointer">
          <Link to="/license">Лицензия</Link>
        </li>

        
          
        
      </ul>
    </nav>
  );
};

export default Navbar;
