const LicenseComponent = () => {
  return (
    <div className="max-w-4xl mx-auto p-6 bg-white shadow-lg rounded-lg">
      <h1 className="text-3xl font-bold text-center mb-6">Лицензия на использование фотографий</h1>
      
      <h2 className="text-2xl font-semibold mt-4 mb-2">Что мы продаём?</h2>
      <p className="mb-4">Мы продаём права на использование изображений.</p>
      
      <h2 className="text-2xl font-semibold mt-4 mb-2">Условия лицензии</h2>
      <ul className="list-disc list-inside mb-4">
        <li><strong>Срок действия:</strong> 10 лет с автоматической пролонгацией.</li>
        <li><strong>Web-лицензия:</strong> Однократное использование с указанием автора.</li>
        <li><strong>Стандартная лицензия:</strong> Использование в полиграфии до 250,000 экз.</li>
        <li><strong>Расширенная лицензия:</strong> Неограниченное использование в коммерческой продукции.</li>
      </ul>
      
      <h2 className="text-2xl font-semibold mt-4 mb-2">Цена</h2>
      <p className="mb-4">Цена любого изображения, представленного на сайте, составляет 1000 рублей.</p>
      
      <h2 className="text-2xl font-semibold mt-4 mb-2">Покупка изображений</h2>
      <p className="mb-4">Чтобы приобрести изображения, пожалуйста, <a href="/contacts" className="text-blue-500 underline">свяжитесь с правообладателем</a>.</p>
      
      <h2 className="text-2xl font-semibold mt-4 mb-2">Ограничения</h2>
      <p className="mb-4">Покупатель не имеет права использовать изображения в запрещенных контекстах, включая:</p>
      <ul className="list-disc list-inside mb-4">
        <li>Рекламу табачной и алкогольной продукции.</li>
        <li>Использование в неэтичном контексте.</li>
      </ul>
    </div>
  );
};

export default LicenseComponent;
