// Footer.js
import React from 'react';

const Footer = () => {
  return (
    <footer className="bg-gray-900 text-white py-5 mt-10 text-center shadow-md">
      <p className="text-sm">© 2024 Фотогалерея. Все права защищены.</p>
    </footer>
  );
};

export default Footer;
